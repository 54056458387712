import type {IIoTStateRecord} from '../core/iot.js';
import type {
    TDeviceShadowDeviceConfigState,
    TDeviceShadowSensorsConfigState,
    TDeviceShadowSystemConfigState
} from './iot.js';
import type {IEntity} from './permissions.js';
import {EEntityStatus, EEntityType} from './permissions.js';

enum EElevatorInstallationType {
    SingleDevice = 0,
    MultiDevice = 1
}

enum EElevatorStatus {
    Normal = 'normal',
    Service = 'service',
    Emergency = 'emergency',
    Running = 'running',
    Busy = 'busy',
    Positioning = 'positioning',
    Faulty = 'faulty',
    Setup = 'setup',
    Inactive = 'inactive',
    Rebooting = 'rebooting',
    Resetting = 'resetting',
    Upgrading = 'upgrading',
    Offline = 'offline',
    Unknown = 'unknown'
}

enum ERemoteControlAction {
    OpenDoor = 'open-door',
    CloseDoor = 'close-door',
    OpenAndHold = 'open-and-hold',
    CloseAndHold = 'close-and-hold',
    EmergencyStop = 'emergency-stop',
    CabinAlarm = 'cabin-alarm',
    FloorCall = 'floor-call'
}

enum EElevatorServiceStatus {
    Active = 'active',
    OnSite = 'on-site',
    Resolved = 'resolved'
}

enum EElevatorIssueStatus {
    Active = 'active',
    Resolved = 'resolved'
}

enum EEmergencyStatus {
    Received = 'received',
    Acknowledged = 'acknowledged',
    InService = 'in-service',
    Resolved = 'resolved'
}

enum EElevatorOperationMode {
    Normal = 0,
    Service = 1,
    Emergency = 2
}

enum EElevatorScheduler {
    SA = 0, // SIMPLE AUTOMATIC
    DC = 1, // DOWN COLLECTIVE
    UC = 2, // UP COLLECTIVE
    FC = 3 // FULL COLLECTIVE
}

enum EElevatorDirection {
    None = 0,
    Down = 1,
    Up = 2
}

enum EElevatorDoorType {
    Automatic = 0,
    SemiAutomatic = 1,
    Manual = 2
}

enum EElevatorControlLevel {
    L0 = 'L0',
    L1 = 'L1',
    L2 = 'L2',
    L3 = 'L3'
}

enum EElevatorDeviceType {
    ICUD = 0,
    HUFD = 1
}

interface IDeviceRegistry {
    deviceType: EElevatorDeviceType;
    manufacturer: string;
    hardwareVersion: string;
}

enum EElevatorType {
    Passenger = 0,
    Freight = 1,
    Service = 2
}

enum EElevatorMotionSystem {
    Hydraulic = 0,
    Traction = 1
}

enum EDeviceCanPosition {
    Bottom = 0,
    Middle = 1,
    Top = 2
}

enum EElevatorServiceRoutine {
    SafetyTestRun = 'safety-test-run',
    SanitizationRun = 'sanitization-run'
}

enum EElevatorDoorLocation {
    Cabin = 0,
    Hall = 1
}

enum EPremiseDeviceStatus {
    Unknown = -1,
    Ready = 0,
    Busy = 1,
    Positioning = 2
}

enum EPremisePowerSourceStatus {
    Unknown = -1,
    Main = 0,
    Backup = 1
}

enum EPremiseMainPowerStatus {
    Unknown = -1,
    Normal = 0,
    Faulty = 1
}

enum EPremiseBackupPowerStatus {
    Unknown = -1,
    Normal = 0,
    Faulty = 1
}

enum EPremiseConnectivityStatus {
    Unknown = 'unknown',
    Normal = 'normal',
    Faulty = 'faulty'
}

enum EElevatorIssueCode {
    Lighting = 'lighting'
}

enum EAnomaliesCodes {
    DOOR_FAULT = 'DFD',
    OUT_OF_SERVICE = 'OOS',
    LIGHT_WARNING = 'CLS',
    BATTERY_FAULT = 'BBU',
    AC_POWER_FAULT = 'AC'
}

enum ERoutinesCodes {
    SAFETY_TEST = 'STR',
    SANITIZATION_RUN = 'ASPN'
}

enum ESensorLocation {
    Cabin = 'cabin',
    Shaft = 'shaft',
    Door = 'door',
    Hall = 'hall',
    Machine = 'machine',
    Controller = 'controller'
}

enum ESensorType {
    Light = 'light',
    Temp = 'temp',
    Hum = 'hum',
    Pres = 'pres',
    Acc = 'acc',
    Gyro = 'gyro',
    Mag = 'mag',
    Prox = 'prox',
    Sound = 'sound',
    Vib = 'vib',
    Force = 'force'
}

type TSensorId<T extends string> = `${ESensorLocation}-${ESensorType}-${T}`;

type TElevatorScope =
    | EEntityType.Platform
    | EEntityType.ServiceCompany
    | EEntityType.Site
    | EEntityType.ElevatorGroup
    | EEntityType.Elevator;

interface IElevatorSummary extends IEntity<EEntityType.Elevator> {
    status: EElevatorStatus;
    operationMode: EElevatorOperationMode;
    name: string;
    currentStopKey: number;
    direction: 1 | 2 | null;
}

const defaultElevatorSummary: IElevatorSummary = {
    status: EElevatorStatus.Unknown,
    operationMode: EElevatorOperationMode.Service,
    name: '',
    currentStopKey: 0,
    direction: null,
    entityId: '',
    entityType: EEntityType.Elevator,
    entityStatus: EEntityStatus.Pending,
    parentId: '',
    team: []
};

interface IElevatorStateBase {
    connectivityStatus: EPremiseConnectivityStatus;
    powerSourceStatus: EPremisePowerSourceStatus;
    mainPowerStatus: EPremiseMainPowerStatus;
    backupPowerStatus: EPremiseBackupPowerStatus;
    hardwareVersion: string;
    softwareVersion: string;
    lastStopKey: number;
    currentStopKey: number;
    nextStopKey: number | null;
    lastDestinationKey: number | null;
    currentDestinationKey: number | null;
    nextDestinationKey: number | null;
    direction: 1 | 2 | null;
    passengers: string[];
}

interface IElevatorState extends IElevatorStateBase {
    elevatorId: string;
    operationMode: EElevatorOperationMode;
    elevatorStatus: EElevatorStatus;
}

const defaultElevatorState: IElevatorState = {
    elevatorId: '',
    operationMode: EElevatorOperationMode.Service,
    elevatorStatus: EElevatorStatus.Unknown,
    connectivityStatus: EPremiseConnectivityStatus.Unknown,
    powerSourceStatus: EPremisePowerSourceStatus.Unknown,
    mainPowerStatus: EPremiseMainPowerStatus.Unknown,
    backupPowerStatus: EPremiseBackupPowerStatus.Unknown,
    hardwareVersion: '',
    softwareVersion: '',
    lastStopKey: 0,
    currentStopKey: 0,
    nextStopKey: null,
    lastDestinationKey: null,
    currentDestinationKey: null,
    nextDestinationKey: null,
    direction: null,
    passengers: []
};

interface IElevatorProfile {
    elevatorId: string;
    name: string;
    manufacturer: string | null;
    model: string | null;
    year: number | null;
}

const defaultElevatorProfile: IElevatorProfile = {
    elevatorId: '',
    name: '',
    manufacturer: null,
    model: null,
    year: null
};

interface IElevatorGeneralSettings {
    elevatorId: string;
    operationMode: IIoTStateRecord<EElevatorOperationMode>;
    remoteControl: IIoTStateRecord<boolean>;
    fullHandoff: IIoTStateRecord<boolean>;
}

const defaultElevatorGeneralSettings: IElevatorGeneralSettings = {
    elevatorId: '',
    operationMode: {
        desired: EElevatorOperationMode.Service,
        reported: null
    },
    remoteControl: {
        desired: false,
        reported: null
    },
    fullHandoff: {
        desired: false,
        reported: null
    }
};

interface IElevatorStatusReport {
    connectivityStatus: EPremiseConnectivityStatus;
    powerSourceStatus: EPremisePowerSourceStatus;
    mainPowerStatus: EPremiseMainPowerStatus;
    backupPowerStatus: EPremiseBackupPowerStatus;
    operationMode: EElevatorOperationMode;
    elevatorStatus: EElevatorStatus;
    remoteControl: boolean;
    fullHandoff: boolean;
}

interface IElevatorDeviceConfig {
    doorType: EElevatorDoorType;
    doorSensor: boolean;
    loraState: number;
    loraId: number;
    loraTxPower: number;
    canState: number;
    canPosition: number;
    wiFiSSID: string;
    wiFiPassword: string;
    outOfServiceTimeout: number;
    doorFaultTimeout: number;
    callRepeatTimeout: number;
    callRepeatPeriod: number;
}

interface IElevatorPathDescriptor {
    key: number;
    stops: number;
    width: number;
    depth: number;
    height: number;
}

interface IElevatorDoorDescriptor {
    doorKey: number;
    stopKey: number;
    type: EElevatorDoorType;
    location: EElevatorDoorLocation;
}

interface IElevatorSystemConfig {
    general: {
        elevatorType: EElevatorType;
        capacity: number;
        motionSystem: EElevatorMotionSystem;
        nominalSpeed: number;
    };
    motor: {
        type: string;
        nominalPower: number;
        nominalCurrent: number;
        nominalVoltage: number;
    };
    doors: IElevatorDoorDescriptor[];
    paths: IElevatorPathDescriptor[];
}

const defaultElevatorSystemConfig: IElevatorSystemConfig = {
    general: {
        elevatorType: EElevatorType.Passenger,
        capacity: 0,
        motionSystem: EElevatorMotionSystem.Traction,
        nominalSpeed: 0
    },
    motor: {
        type: '',
        nominalPower: 0,
        nominalCurrent: 0,
        nominalVoltage: 0
    },
    doors: [],
    paths: []
};

interface ICommDescriptor {
    protocol: string;
    version: string;
    hostname: string;
    pathname: string;
    port: number;
}

interface IDeviceSensors {
    thresholds: TSensorsThresholds;
}

interface IConfigurationTemplate {
    systemPlate: IElevatorSystemConfig;
    configuration: IElevatorDeviceConfig;
    sensors: IDeviceSensors;
}

const defaultConfigurationTemplate: IConfigurationTemplate = {
    systemPlate: {
        general: {
            elevatorType: EElevatorType.Passenger,
            capacity: 0,
            motionSystem: EElevatorMotionSystem.Hydraulic,
            nominalSpeed: 0
        },
        motor: {
            type: '',
            nominalPower: 2,
            nominalCurrent: 0,
            nominalVoltage: 0
        },
        doors: [
            {
                stopKey: 0,
                doorKey: 0,
                type: EElevatorDoorType.Automatic,
                location: EElevatorDoorLocation.Cabin
            }
        ],
        paths: [
            {
                key: 0,
                stops: 0,
                width: 0,
                depth: 0,
                height: 0
            }
        ]
    },
    configuration: {
        doorType: EElevatorDoorType.Automatic,
        doorSensor: false,
        loraState: 1,
        loraId: 17,
        loraTxPower: 2,
        canState: 0,
        canPosition: EDeviceCanPosition.Bottom,
        wiFiSSID: 'MCALLINN_WiFi',
        wiFiPassword: 'McAllinn@1379',
        outOfServiceTimeout: 120,
        doorFaultTimeout: 300,
        callRepeatTimeout: 8,
        callRepeatPeriod: 8
    },
    sensors: {
        thresholds: {
            'cabin-light-0': {
                max: 1000,
                min: 500
            }
        }
    }
};

interface IGetConfigurationTemplate {
    systemPlate: TDeviceShadowSystemConfigState;
    configuration: TDeviceShadowDeviceConfigState;
    sensors: TDeviceShadowSensorsConfigState;
}

const defaultGetConfigurationTemplate: IGetConfigurationTemplate = {
    systemPlate: {
        general: {
            desired: {
                elevatorType: EElevatorType.Passenger,
                capacity: 0,
                motionSystem: EElevatorMotionSystem.Hydraulic,
                nominalSpeed: 0
            },
            reported: {
                elevatorType: EElevatorType.Passenger,
                capacity: 0,
                motionSystem: EElevatorMotionSystem.Hydraulic,
                nominalSpeed: 0
            }
        },
        motor: {
            desired: {
                type: '',
                nominalPower: 0,
                nominalCurrent: 0,
                nominalVoltage: 0
            },
            reported: {
                type: '',
                nominalPower: 0,
                nominalCurrent: 0,
                nominalVoltage: 0
            }
        },
        doors: {
            desired: [
                {
                    stopKey: 0,
                    doorKey: 0,
                    type: EElevatorDoorType.Automatic,
                    location: EElevatorDoorLocation.Cabin
                }
            ],
            reported: [
                {
                    stopKey: 0,
                    doorKey: 0,
                    type: EElevatorDoorType.Automatic,
                    location: EElevatorDoorLocation.Cabin
                }
            ]
        },
        paths: {
            desired: [
                {
                    key: 0,
                    stops: 0,
                    width: 0,
                    depth: 0,
                    height: 0
                }
            ],
            reported: [
                {
                    key: 0,
                    stops: 0,
                    width: 0,
                    depth: 0,
                    height: 0
                }
            ]
        }
    },
    configuration: {
        doorType: {
            desired: EElevatorDoorType.Automatic,
            reported: EElevatorDoorType.Automatic
        },
        doorSensor: {
            desired: false,
            reported: false
        },
        loraState: {
            desired: 0,
            reported: 0
        },
        loraId: {
            desired: 0,
            reported: 0
        },
        loraTxPower: {
            desired: 0,
            reported: 0
        },
        canState: {
            desired: 0,
            reported: 0
        },
        canPosition: {
            desired: EDeviceCanPosition.Bottom,
            reported: EDeviceCanPosition.Bottom
        },
        wiFiSSID: {
            desired: '',
            reported: ''
        },
        wiFiPassword: {
            desired: '',
            reported: ''
        },
        outOfServiceTimeout: {
            desired: 0,
            reported: 0
        },
        doorFaultTimeout: {
            desired: 0,
            reported: 0
        },
        callRepeatTimeout: {
            desired: 0,
            reported: 0
        },
        operationMode: {
            desired: EElevatorOperationMode.Normal,
            reported: EElevatorOperationMode.Normal
        },
        fullHandoff: {
            desired: false,
            reported: false
        },
        recordingModeState: {
            desired: 0,
            reported: 0
        },
        scheduler: {
            desired: EElevatorScheduler.SA,
            reported: EElevatorScheduler.SA
        },
        remoteControl: {
            desired: false,
            reported: false
        },
        callRepeatPeriod: {
            desired: 0,
            reported: 0
        }
    },
    sensors: {
        thresholds: {
            desired: {
                'cabin-light-0': {
                    max: 0,
                    min: 0
                }
            },
            reported: {
                'cabin-light-0': {
                    max: 0,
                    min: 0
                }
            }
        }
    }
};

interface IElevatorTechnicalParameters {
    elevatorId: string;
    version: string;
    general: {
        elevatorType: EElevatorType;
        capacity: number;
        motionSystem: EElevatorMotionSystem;
        nominalSpeed: number;
    };
    motor: {
        type: string;
        nominalPower: number;
        nominalCurrent: number;
        nominalVoltage: number;
    };
    pit: {
        stops: number;
        floorHeight: number;
        width: number;
        depth: number;
        height: number;
    };
    doors: {
        numberOfCabinDoors: number;
    };
    sensors: Record<
        string,
        {
            max: number;
            min: number;
        }
    >;
}

const defaultElevatorTechnicalParameters: IElevatorTechnicalParameters = {
    elevatorId: '',
    version: '',
    general: {
        elevatorType: EElevatorType.Passenger,
        capacity: 0,
        motionSystem: EElevatorMotionSystem.Traction,
        nominalSpeed: 0
    },
    motor: {
        type: '',
        nominalPower: 0,
        nominalCurrent: 0,
        nominalVoltage: 0
    },
    pit: {
        stops: 0,
        floorHeight: 0,
        width: 0,
        depth: 0,
        height: 0
    },
    doors: {
        numberOfCabinDoors: 0
    },
    sensors: {
        'cabin-light-0': {
            max: 0,
            min: 0
        }
    }
};

interface IElevatorDevice {
    deviceId: string;
    deviceType: EElevatorDeviceType;
    elevatorId: string;
    stopId: string;
    status: boolean | null;
    destinationId: string | null;
    provisioned: boolean;
    key: number;
    number: number;
    sessionIdentifier: string | null;
    sessionVersion: number | null;
    sessionStartedAt: number | null;
    sessionEndedAt: number | null;
}

const defaultElevatorDevice: IElevatorDevice = {
    deviceId: '',
    deviceType: EElevatorDeviceType.HUFD,
    elevatorId: '',
    stopId: '',
    status: null,
    destinationId: '',
    provisioned: false,
    key: 0,
    number: 0,
    sessionIdentifier: null,
    sessionVersion: null,
    sessionStartedAt: null,
    sessionEndedAt: null
};

interface IElevatorDestination {
    destinationId: string;
    elevatorId: string;
    deviceId: string;
    stopId: string;
    key: number;
    name: string;
    number: number;
    tenants: string[];
    door: number;
    destinationType: string;
}

interface IElevatorService {
    serviceId: string;
    serviceEta: number | null;
    status: EElevatorServiceStatus;
    elevatorId: string;
    startedAt: number;
    endedAt: number | null;
    createdBy: string;
}

interface IElevatorIssue {
    issueId: string;
    status: EElevatorIssueStatus;
    elevatorId: string;
    startedAt: number;
    endedAt: number | null;
    code: string;
    src: string;
    pos: number;
    action: 0 | 1 | 2;
    min: number | null;
    max: number | null;
    value: number | null;
}

interface IElevatorAnomaly {
    anomalyId: string;
    elevatorId: string;
    timestamp: number;
    code: string;
    src: string;
    pos: number;
    action: 0 | 1 | 2;
    min: number | null;
    max: number | null;
    value: number | null;
}

interface IElevatorTrip {
    tripId: string;
    elevatorId: string;
    startedAt: number;
    endedAt: number | null;
    stopFrom: number;
    stopTo: number | null;
    destFrom: number | null;
    destTo: number | null;
    pos: number;
    dir: 1 | 2;
    ctrl: 0 | 1 | 2 | 3 | null;
    by: string | null;
    passengers: string[];
}

interface IElevatorFull {
    summary: IElevatorSummary;
    state: IElevatorState;
    profile: IElevatorProfile;
    generalSettings: IElevatorGeneralSettings;
}

const defaultElevatorFull: IElevatorFull = {
    summary: defaultElevatorSummary,
    state: defaultElevatorState,
    profile: defaultElevatorProfile,
    generalSettings: defaultElevatorGeneralSettings
};

interface IElevatorTenant {
    elevatorId: string;
    tenantId: string;
    stopId: string;
    destinationId: string;
    name: string;
}

interface IEmergency {
    emergencyId: string;
    status: EEmergencyStatus;
    elevatorId: string;
    issueId: string;
    startedAt: number;
    endedAt: number | null;
    reason: string;
    eta: number | null;
    acknowledgedAt: number | null;
    acknowledgedBy: string | null;
    resolvedAt: number | null;
    state: IElevatorStateBase;
}

const defaultElevatorEmergency: IEmergency = {
    emergencyId: '',
    status: EEmergencyStatus.Received,
    elevatorId: '',
    issueId: '',
    startedAt: 0,
    endedAt: null,
    reason: '',
    eta: null,
    acknowledgedAt: null,
    acknowledgedBy: null,
    resolvedAt: null,
    state: {
        connectivityStatus: EPremiseConnectivityStatus.Unknown,
        powerSourceStatus: EPremisePowerSourceStatus.Unknown,
        mainPowerStatus: EPremiseMainPowerStatus.Unknown,
        backupPowerStatus: EPremiseBackupPowerStatus.Unknown,
        hardwareVersion: '',
        softwareVersion: '',
        lastStopKey: 0,
        currentStopKey: 0,
        nextStopKey: null,
        lastDestinationKey: null,
        currentDestinationKey: null,
        nextDestinationKey: null,
        direction: null,
        passengers: []
    }
};

interface IElevatorStop {
    elevatorId: string;
    name: string;
    stopId: string;
    key: number;
    index: number;
    doors: number[];
}

interface IDeviceDescriptor {
    elevatorId: string;
    id: string;
    stopId: string;
    destinationId: string;
    deviceType: EElevatorDeviceType;
}

interface IStopMap {
    stopKey: number;
    index: number;
    distanceToNext: number | null;
}

type TStopsLayout = IStopMap[];

interface IStopShadowMap {
    stopKey: number;
    distanceToNext: number | null;
}

type TStopsShadowLayout = IStopShadowMap[];

interface IDestinationMap {
    destinationKey: number;
    stopKey: number;
    doorKey: number;
}

type TDestinationsLayout = IDestinationMap[];

interface IDeviceCertificate {
    elevatorId: string;
    destinationId: string | null;
    publicKey: string;
    privateKey: string;
    pem: string;
    deviceId: string;
    deviceType: number;
}

enum EElevatorDestinationType {
    Public = 'public',
    Private = 'private'
}

interface IDeviceRegistry {
    deviceType: EElevatorDeviceType;
    manufacturer: string;
    hardwareVersion: string;
}

type TSensorsThresholds = Record<
    string,
    {
        max: number;
        min: number;
    }
>;

const defaultSensorsThresholds: TSensorsThresholds = {
    'cabin-light-0': {
        max: 0,
        min: 0
    },
    'shaft-light-0': {
        max: 0,
        min: 0
    },
    'door-light-0': {
        max: 0,
        min: 0
    }
};

interface ICommDescriptor {
    protocol: string;
    version: string;
    hostname: string;
    pathname: string;
    port: number;
}

interface IDeviceDescriptor {
    elevatorId: string;
    id: string;
    stopId: string;
    destinationId: string;
    deviceType: EElevatorDeviceType;
}

interface IDestinationDescriptor {
    distanceToNext: number;
    index: number;
    stopKey: number;
}

interface IDestinationsKPIS {
    destinations: IDestinationDescriptor[];
    elevatorId: string;
    motor: {
        nominalPower: number;
    };
    serviceCompanyId: string;
    siteId: string;
}

export type {
    ICommDescriptor,
    IConfigurationTemplate,
    IDestinationMap,
    IDestinationsKPIS,
    IDeviceCertificate,
    IDeviceDescriptor,
    IDeviceRegistry,
    IDeviceSensors,
    IElevatorAnomaly,
    IElevatorDestination,
    IElevatorDevice,
    IElevatorDeviceConfig,
    IElevatorDoorDescriptor,
    IElevatorFull,
    IElevatorGeneralSettings,
    IElevatorIssue,
    IElevatorPathDescriptor,
    IElevatorProfile,
    IElevatorService,
    IElevatorState,
    IElevatorStateBase,
    IElevatorStatusReport,
    IElevatorStop,
    IElevatorSummary,
    IElevatorSystemConfig,
    IElevatorTechnicalParameters,
    IElevatorTenant,
    IElevatorTrip,
    IEmergency,
    IGetConfigurationTemplate,
    IStopMap,
    TDestinationsLayout,
    TElevatorScope,
    TSensorId,
    TSensorsThresholds,
    TStopsLayout,
    TStopsShadowLayout
};
export {
    EAnomaliesCodes,
    EDeviceCanPosition,
    EElevatorControlLevel,
    EElevatorDestinationType,
    EElevatorDeviceType,
    EElevatorDirection,
    EElevatorDoorLocation,
    EElevatorDoorType,
    EElevatorInstallationType,
    EElevatorIssueCode,
    EElevatorIssueStatus,
    EElevatorMotionSystem,
    EElevatorOperationMode,
    EElevatorScheduler,
    EElevatorServiceRoutine,
    EElevatorServiceStatus,
    EElevatorStatus,
    EElevatorType,
    EEmergencyStatus,
    EPremiseBackupPowerStatus,
    EPremiseConnectivityStatus,
    EPremiseDeviceStatus,
    EPremiseMainPowerStatus,
    EPremisePowerSourceStatus,
    ERemoteControlAction,
    ERoutinesCodes,
    ESensorLocation,
    ESensorType,
    defaultConfigurationTemplate,
    defaultElevatorDevice,
    defaultElevatorEmergency,
    defaultElevatorFull,
    defaultElevatorGeneralSettings,
    defaultElevatorProfile,
    defaultElevatorState,
    defaultElevatorSummary,
    defaultElevatorSystemConfig,
    defaultElevatorTechnicalParameters,
    defaultGetConfigurationTemplate,
    defaultSensorsThresholds
};
