import type {
    EEntityType,
    IElevatorAnomaly,
    IElevatorDestination,
    IElevatorDevice,
    IElevatorGeneralSettings,
    IElevatorIssue,
    IElevatorState,
    IElevatorStop,
    IElevatorSummary,
    IElevatorTrip,
    IEmergency,
    IGetConfigurationTemplate,
    IResolvedPermissions,
    TDeviceShadowDeviceConfigState,
    TDeviceShadowSensorsConfigState,
    TDeviceShadowSystemConfigState,
    TDoorCycleDensityKPI,
    TDoorFaultsTotalKPI,
    TEnergyConsumptionDensityKPI,
    TFloorDistributionKPI,
    TMileageDensityKPI,
    TMileageTotalKPI,
    TOutOfServiceTotalKPI,
    TSafetyTestsTotalKPI,
    TSanitizationRunsTotalKPI,
    TStopsDensityKPI,
    TTimeConsumptionDensityKPI,
    TTripsHistoryKPI
} from '@mcal/core';
import {EElevatorOperationMode, EElevatorStatus} from '@mcal/core';
import {createSelector} from '@reduxjs/toolkit';
import type {TStatus} from '../../defines/common.types.js';
import {ElevatorOperationModeMap} from '../../defines/maps.js';
import type {ITeamMember} from '../../defines/platform.types.js';
import type {
    ESliceRemoteStatus,
    IPartialState,
    ISliceRemote
} from '../../defines/redux.types.js';
import {composeTeam} from '../../utils/compose-team/compose-team.js';
import type {IElevatorSliceState} from './elevator.state.js';

const elevatorSelector = (state: IPartialState): IElevatorSliceState => {
    if (!state.elevator) {
        throw new Error('Elevator slice is not available.');
    }

    return state.elevator;
};

const selectElevatorSummary = createSelector(
    [elevatorSelector],
    (elevator): IElevatorSummary => {
        return elevator.remotes.elevatorSummary.current;
    }
);

const selectElevatorSummaryStatus = createSelector(
    [elevatorSelector],
    (elevator): ESliceRemoteStatus => {
        return elevator.remotes.elevatorSummary.status;
    }
);

const selectElevatorStatus = createSelector(
    [elevatorSelector],
    ({remotes}): TStatus => {
        const elevatorStatus = remotes.elevatorSummary.current.status;

        if (elevatorStatus === EElevatorStatus.Emergency) {
            return EElevatorStatus.Emergency;
        }

        return remotes.elevatorGeneralSettings.current.operationMode
            .reported === EElevatorOperationMode.Service
            ? EElevatorStatus.Service
            : ElevatorOperationModeMap[elevatorStatus];
    }
);

const selectElevatorSummaryRemote = createSelector(
    [elevatorSelector],
    (elevator): ISliceRemote<IElevatorSummary> => {
        return elevator.remotes.elevatorSummary;
    }
);

const selectElevatorState = createSelector(
    [elevatorSelector],
    (elevator): IElevatorState => {
        return elevator.remotes.elevatorState.current;
    }
);

const selectElevatorGeneralSettings = createSelector(
    [elevatorSelector],
    (elevator): IElevatorGeneralSettings => {
        return elevator.remotes.elevatorGeneralSettings.current;
    }
);

const selectElevatorGeneralSettingsStatus = createSelector(
    [elevatorSelector],
    (elevator): ESliceRemoteStatus => {
        return elevator.remotes.elevatorGeneralSettings.status;
    }
);

const selectElevatorConfigurationTemplate = createSelector(
    [elevatorSelector],
    (elevator): IGetConfigurationTemplate => {
        return elevator.remotes.configurationTemplates.current;
    }
);

const selectElevatorSensors = createSelector(
    [elevatorSelector],
    (elevator): TDeviceShadowSensorsConfigState => {
        return elevator.remotes.configurationTemplates.current.sensors;
    }
);

const selectElevatorSystemPlate = createSelector(
    [elevatorSelector],
    (elevator): TDeviceShadowSystemConfigState => {
        return elevator.remotes.configurationTemplates.current.systemPlate;
    }
);

const selectElevatorDeviceConfiguration = createSelector(
    [elevatorSelector],
    (elevator): TDeviceShadowDeviceConfigState => {
        return elevator.remotes.configurationTemplates.current.configuration;
    }
);

const selectElevatorDevices = createSelector(
    [elevatorSelector],
    (elevator): IElevatorDevice[] => {
        return elevator.remotes.elevatorDevices.current;
    }
);

const selectElevatorMileageTotal = createSelector(
    [elevatorSelector],
    (elevator): TMileageTotalKPI | null => {
        return elevator.remotes.kpis.current.mileageTotal;
    }
);

const selectElevatorMileageDensity = createSelector(
    [elevatorSelector],
    (elevator): TMileageDensityKPI | null => {
        return elevator.remotes.kpis.current.mileageDensity;
    }
);

const selectElevatorStopsDensity = createSelector(
    [elevatorSelector],
    (elevator): TStopsDensityKPI | null => {
        return elevator.remotes.kpis.current.stopsDensity;
    }
);
const selectElevatorEnergyConsumptionDensity = createSelector(
    [elevatorSelector],
    (elevator): TEnergyConsumptionDensityKPI | null => {
        return elevator.remotes.kpis.current.energyConsumptionDensity;
    }
);

const selectElevatorDONDensity = createSelector(
    [elevatorSelector],
    (elevator): TDoorCycleDensityKPI | null => {
        return elevator.remotes.kpis.current.doorCyclesDensity;
    }
);

const selectElevatorTimeConsumptionDensity = createSelector(
    [elevatorSelector],
    (elevator): TTimeConsumptionDensityKPI | null => {
        return elevator.remotes.kpis.current.timeConsumptionDensity;
    }
);

const selectElevatorTripHistory = createSelector(
    [elevatorSelector],
    (elevator): TTripsHistoryKPI | null => {
        return elevator.remotes.kpis.current.tripHistory;
    }
);

const selectElevatorTrips = createSelector(
    [elevatorSelector],
    (elevator): ISliceRemote<IElevatorTrip[]> => {
        const stops = elevator.remotes.stops.current;

        const trips = elevator.remotes.trips.current.map((trip) => {
            const stopFrom = stops.find((stop) => stop.key === trip.stopFrom);
            const stopTo = stops.find((stop) => stop.key === trip.stopTo);
            return {
                ...trip,
                stopFrom: stopFrom ? stopFrom.index : trip.stopFrom,
                stopTo: stopTo ? stopTo.index : trip.stopTo,
                duration: trip.endedAt
                    ? `${Math.ceil((trip.endedAt - trip.startedAt) / 1000)} s`
                    : '-'
            };
        }, []);
        return {...elevator.remotes.trips, current: trips};
    }
);

const selectElevatorFloorDistribution = createSelector(
    [elevatorSelector],
    (elevator): TFloorDistributionKPI | null => {
        return elevator.remotes.kpis.current.floorDistribution;
    }
);

const selectElevatorPermissions = createSelector(
    [elevatorSelector],
    (elevator): IResolvedPermissions => {
        return elevator.remotes.permissions.current;
    }
);

const selectElevatorTeam = createSelector(
    [elevatorSelector, (_, affinity: string): string => affinity],
    (elevator, affinity): ITeamMember<EEntityType.Elevator>[] => {
        return composeTeam<EEntityType.Elevator>({
            team: elevator.remotes.teams.current[affinity] || [],
            memberships: elevator.remotes.memberships.current[affinity] || []
        });
    }
);

const selectElevatorGlobalTeam = createSelector(
    [elevatorSelector, (_, affinity: string): string => affinity],
    (
        elevator,
        affinity
    ):
        | ITeamMember<EEntityType.ServiceCompany>[]
        | ITeamMember<EEntityType.Site>[] => {
        return composeTeam<EEntityType.ServiceCompany | EEntityType.Site>({
            team: elevator.remotes.globalTeams.current[affinity] || [],
            memberships:
                elevator.remotes.globalMemberships.current[affinity] || []
        });
    }
);

const selectOutOfServiceTotal = createSelector(
    [elevatorSelector],
    (elevator): TOutOfServiceTotalKPI | null => {
        return elevator.remotes.kpis.current.outOfServiceTotal;
    }
);

const selectDoorFaultsTotal = createSelector(
    [elevatorSelector],
    (elevator): TDoorFaultsTotalKPI | null => {
        return elevator.remotes.kpis.current.doorFaultsTotal;
    }
);

const selectSafetyTestsTotal = createSelector(
    [elevatorSelector],
    (elevator): TSafetyTestsTotalKPI | null => {
        return elevator.remotes.kpis.current.safetyTestsTotal;
    }
);

const selectSanitizationRunsTotal = createSelector(
    [elevatorSelector],
    (elevator): TSanitizationRunsTotalKPI | null => {
        return elevator.remotes.kpis.current.sanitizationRunsTotal;
    }
);

const selectDestinations = createSelector(
    [elevatorSelector],
    (elevator): ISliceRemote<IElevatorDestination[]> => {
        return elevator.remotes.destinations;
    }
);

const selectElevatorIssues = createSelector(
    [elevatorSelector],
    (elevator): ISliceRemote<IElevatorIssue[]> => {
        return elevator.remotes.issues;
    }
);

const selectElevatorAnomalies = createSelector(
    [elevatorSelector],
    (elevator): ISliceRemote<IElevatorAnomaly[]> => {
        const anomalies = elevator.remotes.anomalies.current.map((anomaly) => {
            const currentStop = elevator.remotes.stops.current.find(
                (stop) => stop.key === anomaly.pos
            );
            return {
                ...anomaly,
                pos: currentStop ? currentStop.index : anomaly.pos
            };
        });

        return {...elevator.remotes.anomalies, current: anomalies};
    }
);

const selectElevatorStops = createSelector(
    [elevatorSelector],
    (elevator): ISliceRemote<IElevatorStop[]> => {
        return elevator.remotes.stops;
    }
);

const selectElevatorEmergency = createSelector(
    [elevatorSelector],
    (elevator): IEmergency => {
        return elevator.remotes.emergencies.current;
    }
);

const selectElevatorCurrentDestination = createSelector(
    [elevatorSelector],
    (elevator): IElevatorDestination | object => {
        return elevator.remotes.currentDestination.current;
    }
);

export {
    selectDestinations,
    selectDoorFaultsTotal,
    selectElevatorAnomalies,
    selectElevatorConfigurationTemplate,
    selectElevatorCurrentDestination,
    selectElevatorDONDensity,
    selectElevatorDeviceConfiguration,
    selectElevatorDevices,
    selectElevatorEmergency,
    selectElevatorEnergyConsumptionDensity,
    selectElevatorFloorDistribution,
    selectElevatorGeneralSettings,
    selectElevatorGeneralSettingsStatus,
    selectElevatorGlobalTeam,
    selectElevatorIssues,
    selectElevatorMileageDensity,
    selectElevatorMileageTotal,
    selectElevatorPermissions,
    selectElevatorSensors,
    selectElevatorState,
    selectElevatorStatus,
    selectElevatorStops,
    selectElevatorStopsDensity,
    selectElevatorSummary,
    selectElevatorSummaryRemote,
    selectElevatorSummaryStatus,
    selectElevatorSystemPlate,
    selectElevatorTeam,
    selectElevatorTimeConsumptionDensity,
    selectElevatorTripHistory,
    selectElevatorTrips,
    selectOutOfServiceTotal,
    selectSafetyTestsTotal,
    selectSanitizationRunsTotal
};
