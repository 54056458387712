import type {
    EElevatorOperationMode,
    EElevatorRole,
    IElevatorAnomaly,
    IElevatorDestination,
    IElevatorDevice,
    IElevatorGeneralSettings,
    IElevatorIssue,
    IElevatorProfile,
    IElevatorState,
    IElevatorStop,
    IElevatorSummary,
    IElevatorTrip,
    IEmergency,
    IGetConfigurationTemplate,
    IIoTStateRecord,
    IResolvedPermissions,
    TNestedPartial
} from '@mcal/core';
import {EAffiliationStatus, EElevatorStatus} from '@mcal/core';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {getPermissions} from '../../services/app-gateway/permissions/permissions.thunks.js';
import {getUsersMemberships} from '../../services/app-node-auth/users/users.thunks.js';
import {
    deleteElevatorTeam,
    getElevator,
    getElevatorAnomalies,
    getElevatorConfigurationTemplate,
    getElevatorDeviceConfig,
    getElevatorDevices,
    getElevatorEmergency,
    getElevatorIssues,
    getElevatorState,
    getElevatorSystemConfig,
    getElevatorTeam,
    getElevatorTrips,
    listElevatorDestinations,
    listElevatorStops,
    restoreElevatorEmergency,
    updateElevatorAdvanced,
    updateElevatorDestinations,
    updateElevatorDeviceConfig,
    updateElevatorHandoff,
    updateElevatorOperationMode,
    updateElevatorProfile,
    updateElevatorSensors,
    updateElevatorStops,
    updateElevatorSystemConfig,
    updateElevatorSystemPlate,
    updateElevatorTeam,
    updateRemoteControlMode
} from '../../services/app-node-elevators/elevators/elevators.thunks.js';
import {addMember} from '../../services/app-node-invites/invites/invites.thunks.js';
import {
    getDoorCyclesDensity,
    getDoorFaultsTotal,
    getEnergyConsumptionDenstiy,
    getFloorDistribution,
    getMileageDensity,
    getMileageTotal,
    getOutOfServiceTotal,
    getSafetyTestsTotal,
    getSanitizationRunsTotal,
    getStopsDensity,
    getTimeConsumptionDensity,
    getTripsHistory
} from '../../services/app-node-kpis/kpis/kpis.thunks.js';
import {getServiceCompanyTeam} from '../../services/app-node-service-companies/service-companies/service-companies.thunks.js';
import {getSiteTeam} from '../../services/app-node-sites/sites/sites.thunks.js';
import {fulfilledRemoteUpdate} from '../../utils/fulfilled-remote-update/fulfilled-remote-update.js';
import {pendingRemoteUpdate} from '../../utils/pending-remote-update/pending-remote-update.js';
import {rejectedRemoteUpdate} from '../../utils/rejected-remote-update/rejected-remote-update.js';
import {initialState} from './elevator.state.js';

const elevatorSlice = createSlice({
    name: 'elevator',
    initialState,
    reducers: {
        updateTrips: (state, {payload}: PayloadAction<IElevatorTrip>) => {
            if (
                state.remotes.elevatorSummary.current.entityId !==
                payload.elevatorId
            ) {
                return;
            }

            const alreadySaved = state.remotes.trips.current.find((trip) => {
                return trip.tripId === payload.tripId;
            });

            if (!alreadySaved) {
                state.remotes.trips.current.unshift(payload);
                return;
            }

            state.remotes.trips.current = state.remotes.trips.current.map(
                (trip) => {
                    return trip.tripId === payload.tripId ? payload : trip;
                }
            );
        },
        updateIssues: (state, {payload}: PayloadAction<IElevatorIssue>) => {
            if (
                state.remotes.elevatorSummary.current.entityId !==
                payload.elevatorId
            ) {
                return;
            }

            const alreadySaved = state.remotes.issues.current.find((issue) => {
                return issue.issueId === payload.issueId;
            });

            if (!alreadySaved) {
                state.remotes.issues.current.unshift(payload);
                return;
            }

            state.remotes.issues.current = state.remotes.issues.current.map(
                (issue) => {
                    return issue.issueId === payload.issueId ? payload : issue;
                }
            );
        },
        updateAnomalies: (
            state,
            {payload}: PayloadAction<IElevatorAnomaly>
        ) => {
            if (
                state.remotes.elevatorSummary.current.entityId !==
                payload.elevatorId
            ) {
                return;
            }

            const alreadySaved = state.remotes.anomalies.current.find(
                (anomaly) => {
                    return anomaly.anomalyId === payload.anomalyId;
                }
            );

            if (!alreadySaved) {
                state.remotes.anomalies.current.unshift(payload);
                return;
            }

            state.remotes.anomalies.current =
                state.remotes.anomalies.current.map((anomaly) => {
                    return anomaly.anomalyId === payload.anomalyId
                        ? payload
                        : anomaly;
                });
        },
        updateState: (
            state,
            {payload}: PayloadAction<Partial<IElevatorState>>
        ) => {
            if (
                state.remotes.elevatorState.current.elevatorId !==
                payload.elevatorId
            ) {
                return;
            }

            state.remotes.elevatorState.current = {
                ...state.remotes.elevatorState.current,
                ...payload
            };
        },
        updateSummary: (
            state,
            {payload}: PayloadAction<Partial<IElevatorState>>
        ) => {
            if (
                state.remotes.elevatorSummary.current.entityId !==
                payload.elevatorId
            ) {
                return;
            }

            if (payload.operationMode !== undefined) {
                state.remotes.elevatorSummary.current.operationMode =
                    payload.operationMode as EElevatorOperationMode;
            }

            if (payload.elevatorStatus !== undefined) {
                state.remotes.elevatorSummary.current.status =
                    payload.elevatorStatus as EElevatorStatus;
            }
        },
        updateReportedGeneralSettings: (
            state,
            {
                payload
            }: PayloadAction<{
                operationMode: IIoTStateRecord<EElevatorOperationMode>;
                remoteControl: IIoTStateRecord<boolean>;
                elevatorId: string;
            }>
        ) => {
            if (
                state.remotes.elevatorGeneralSettings.current.elevatorId !==
                payload.elevatorId
            ) {
                return;
            }

            state.remotes.elevatorGeneralSettings.current = {
                ...state.remotes.elevatorGeneralSettings.current,
                remoteControl: payload.remoteControl,
                operationMode: payload.operationMode
            };

            state.remotes.elevatorSummary.current = {
                ...state.remotes.elevatorSummary.current,
                operationMode:
                    payload.operationMode.reported !== null
                        ? payload.operationMode.reported
                        : state.remotes.elevatorSummary.current.operationMode
            };
        },
        setCurrentDestination: (
            state,
            {payload}: PayloadAction<IElevatorDestination>
        ) => {
            if (
                state.remotes.elevatorSummary.current.entityId !==
                    payload.elevatorId ||
                payload.destinationId ===
                    (
                        state.remotes.currentDestination
                            .current as IElevatorDestination
                    ).destinationId
            ) {
                return;
            }

            state.remotes.currentDestination.current = payload;
        },
        resetSlice: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getElevator.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    'elevatorSummary',
                    'elevatorState',
                    'elevatorProfile',
                    'elevatorGeneralSettings'
                ]
            });
        });
        builder.addCase(getElevator.fulfilled, (state, {payload, meta}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'elevatorSummary',
                        replacer: (data): IElevatorSummary => {
                            return data.summary;
                        }
                    },
                    {
                        key: 'elevatorState',
                        replacer: (data): IElevatorState => {
                            return data.state;
                        }
                    },
                    {
                        key: 'elevatorProfile',
                        replacer: (data): IElevatorProfile => {
                            return data.profile;
                        }
                    },
                    {
                        key: 'elevatorGeneralSettings',
                        replacer: (data): IElevatorGeneralSettings => {
                            return data.generalSettings;
                        }
                    },
                    {
                        key: 'currentDestination',
                        replacer: (data): IElevatorDestination | object => {
                            // We get both Destinations and Stops in here
                            const destinations =
                                state.remotes.destinations.current;
                            const stops = state.remotes.stops.current;
                            // First we get the current stop
                            const foundStop = stops.find(
                                (stop) => stop.key === data.state.currentStopKey
                            );
                            // if no stop something is off so we return an empty object
                            if (!foundStop) {
                                return {};
                            }

                            // We then look for the destination that matches the stopId
                            // this could match
                            const foundDest = destinations.find(
                                (destination) =>
                                    destination.stopId === foundStop.stopId
                            );

                            if (foundDest) {
                                return foundDest;
                            }

                            return {};
                        }
                    }
                ]
            });
        });
        builder.addCase(getElevator.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    'elevatorSummary',
                    'elevatorState',
                    'elevatorProfile',
                    'elevatorGeneralSettings'
                ]
            });
        });

        builder.addCase(getElevatorState.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['elevatorState']
            });
        });

        builder.addCase(
            getElevatorState.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'elevatorState',
                            replacer: (data): IElevatorState => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(getElevatorState.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['elevatorState']
            });
        });

        builder.addCase(getElevatorTeam.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['teams']
            });
        });
        builder.addCase(getElevatorTeam.fulfilled, (state, {payload, meta}) => {
            if (
                (state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.elevatorId !==
                        state.remotes.elevatorSummary.current.entityId) ||
                !meta.arg.affinityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'teams',
                        mutator: (data, current): void => {
                            current[meta.arg.affinityId] = data;
                        }
                    }
                ]
            });
        });
        builder.addCase(getElevatorTeam.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['teams']
            });
        });

        builder.addCase(getUsersMemberships.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['memberships']
            });
        });
        builder.addCase(
            getUsersMemberships.fulfilled,
            (state, {payload, meta}) => {
                const delegator = state.remotes.permissions.current.access.find(
                    (access) => {
                        return access.entityId === meta.arg.entityId;
                    }
                );

                if (delegator && meta.arg.entityId === delegator.entityId) {
                    fulfilledRemoteUpdate({
                        state,
                        meta,
                        payload,
                        targets: [
                            {
                                key: 'globalMemberships',
                                mutator: (data, current): void => {
                                    current[meta.arg.entityId] = data;
                                }
                            }
                        ]
                    });
                }

                if (
                    meta.arg.entityId !==
                        state.remotes.elevatorSummary.current.entityId ||
                    !meta.arg.affinityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'memberships',
                            mutator: (data, current): void => {
                                current[meta.arg.entityId] = data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getUsersMemberships.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.entityId !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['memberships']
                });
            }
        );

        builder.addCase(
            getElevatorConfigurationTemplate.pending,
            (state, {meta}) => {
                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: ['configurationTemplates']
                });
            }
        );
        builder.addCase(
            getElevatorConfigurationTemplate.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'configurationTemplates',
                            replacer: (data): IGetConfigurationTemplate => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getElevatorConfigurationTemplate.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['configurationTemplates']
                });
            }
        );

        // GET ELEVATOR DEVICES
        builder.addCase(getElevatorDevices.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['elevatorDevices']
            });
        });

        builder.addCase(
            getElevatorDevices.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'elevatorDevices',
                            replacer: (data): IElevatorDevice[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            getElevatorDevices.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorDevices']
                });
            }
        );

        // ELEVATOR CONFIGURATIONS

        // GET ELEVATOR CONFIGURATIONS SYSTEM

        builder.addCase(getElevatorSystemConfig.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['configurationTemplates']
            });
        });

        builder.addCase(
            getElevatorSystemConfig.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'configurationTemplates',
                            mutator: (args, current): void => {
                                current.configuration.recordingModeState.desired =
                                    args.recordingModeState;
                                current.configuration.scheduler.desired =
                                    args.scheduler;
                                current.configuration.wiFiSSID.desired =
                                    args.wiFiSSID;
                                current.configuration.outOfServiceTimeout.desired =
                                    args.outOfServiceTimeout;
                                current.configuration.doorFaultTimeout.desired =
                                    args.doorFaultTimeout;
                                current.configuration.callRepeatTimeout.desired =
                                    args.callRepeatTimeout;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            getElevatorSystemConfig.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['configurationTemplates']
                });
            }
        );

        // GET ELEVATOR CONFIGURATIONS DEVICE

        builder.addCase(getElevatorDeviceConfig.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['configurationTemplates']
            });
        });

        builder.addCase(
            getElevatorDeviceConfig.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'configurationTemplates',
                            mutator: (args, current): void => {
                                current.configuration.loraState.desired =
                                    args.loraState;
                                current.configuration.loraId.desired =
                                    args.loraId;
                                current.configuration.loraTxPower.desired =
                                    args.loraTxPower;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            getElevatorDeviceConfig.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['configurationTemplates']
                });
            }
        );

        // ---- UPDATE ELEVATOR CONFIGURATIONS SYSTEM

        builder.addCase(updateElevatorSystemConfig.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['configurationTemplates']
            });
        });

        builder.addCase(
            updateElevatorSystemConfig.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'configurationTemplates',
                            mutator: (_, current): void => {
                                if (
                                    meta.arg.configuration.recordingModeState &&
                                    meta.arg.configuration.scheduler &&
                                    meta.arg.configuration.wiFiSSID &&
                                    meta.arg.configuration
                                        .outOfServiceTimeout &&
                                    meta.arg.configuration.doorFaultTimeout &&
                                    meta.arg.configuration.callRepeatTimeout
                                ) {
                                    current.configuration.recordingModeState.desired =
                                        meta.arg.configuration.recordingModeState;
                                    current.configuration.scheduler.desired =
                                        meta.arg.configuration.scheduler;
                                    current.configuration.wiFiSSID.desired =
                                        meta.arg.configuration.wiFiSSID;
                                    current.configuration.outOfServiceTimeout.desired =
                                        meta.arg.configuration.outOfServiceTimeout;
                                    current.configuration.doorFaultTimeout.desired =
                                        meta.arg.configuration.doorFaultTimeout;
                                    current.configuration.callRepeatTimeout.desired =
                                        meta.arg.configuration.callRepeatTimeout;
                                }
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            updateElevatorSystemConfig.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['configurationTemplates']
                });
            }
        );

        // ---- ELEVATOR DEVICE CONFIG

        builder.addCase(updateElevatorDeviceConfig.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['configurationTemplates']
            });
        });

        builder.addCase(
            updateElevatorDeviceConfig.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'configurationTemplates',
                            mutator: (_, current): void => {
                                current.configuration.loraState.desired =
                                    meta.arg.configuration.loraState;
                                current.configuration.loraId.desired =
                                    meta.arg.configuration.loraId;
                                current.configuration.loraTxPower.desired =
                                    meta.arg.configuration.loraTxPower;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            updateElevatorDeviceConfig.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['configurationTemplates']
                });
            }
        );

        // ----------------------------

        builder.addCase(updateElevatorProfile.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'elevatorProfile',
                        merger: (arg): TNestedPartial<IElevatorProfile> => {
                            return arg;
                        }
                    },
                    {
                        key: 'elevatorSummary',
                        merger: (arg): TNestedPartial<IElevatorSummary> => {
                            return {name: arg.profile.name};
                        }
                    }
                ]
            });
        });

        builder.addCase(
            updateElevatorProfile.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorProfile', 'elevatorSummary']
                });
            }
        );
        builder.addCase(
            updateElevatorProfile.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorProfile']
                });
            }
        );

        builder.addCase(updateElevatorHandoff.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'elevatorGeneralSettings',
                        mutator: (arg, current): void => {
                            current.fullHandoff.desired = arg.enable;
                        }
                    }
                ]
            });
        });

        builder.addCase(
            updateElevatorHandoff.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorGeneralSettings']
                });
            }
        );
        builder.addCase(
            updateElevatorHandoff.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorGeneralSettings']
                });
            }
        );

        builder.addCase(
            updateElevatorOperationMode.pending,
            (state, {meta}) => {
                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: [
                        {
                            key: 'elevatorGeneralSettings',
                            mutator: (arg, current): void => {
                                current.operationMode.desired = arg.mode;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            updateElevatorOperationMode.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorGeneralSettings']
                });
            }
        );
        builder.addCase(
            updateElevatorOperationMode.rejected,
            (state, {payload, meta}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorGeneralSettings']
                });
            }
        );

        builder.addCase(updateElevatorSystemPlate.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'configurationTemplates',
                        mutator: (arg, current): void => {
                            current.systemPlate.doors.desired =
                                arg.systemPlate.doors;
                            current.systemPlate.general.desired =
                                arg.systemPlate.general;
                            current.systemPlate.motor.desired =
                                arg.systemPlate.motor;
                            current.systemPlate.paths.desired =
                                arg.systemPlate.paths;
                        }
                    }
                ]
            });
        });
        builder.addCase(
            updateElevatorSystemPlate.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['configurationTemplates']
                });
            }
        );
        builder.addCase(
            updateElevatorSystemPlate.rejected,
            (state, {payload, meta}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['configurationTemplates']
                });
            }
        );

        builder.addCase(updateElevatorSensors.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'configurationTemplates',
                        merger: (
                            arg
                        ): TNestedPartial<IGetConfigurationTemplate> => {
                            return {sensors: arg.sensors};
                        }
                    }
                ]
            });
        });
        builder.addCase(
            updateElevatorSensors.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['configurationTemplates']
                });
            }
        );
        builder.addCase(
            updateElevatorSensors.rejected,
            (state, {payload, meta}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['configurationTemplates']
                });
            }
        );

        builder.addCase(updateElevatorAdvanced.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'configurationTemplates',
                        merger: (
                            arg
                        ): TNestedPartial<IGetConfigurationTemplate> => {
                            return {
                                configuration: (
                                    arg as unknown as {advanced: object}
                                ).advanced
                            };
                        }
                    }
                ]
            });
        });
        builder.addCase(
            updateElevatorAdvanced.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['configurationTemplates']
                });
            }
        );
        builder.addCase(
            updateElevatorAdvanced.rejected,
            (state, {payload, meta}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['configurationTemplates']
                });
            }
        );

        builder.addCase(getMileageTotal.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });
        builder.addCase(getMileageTotal.fulfilled, (state, {payload, meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'kpis',
                        mutator: (data, current): void => {
                            current.mileageTotal = data;
                        }
                    }
                ]
            });
        });
        builder.addCase(getMileageTotal.rejected, (state, {meta, payload}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                payload,
                meta,
                targets: ['kpis']
            });
        });

        builder.addCase(getMileageDensity.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });
        builder.addCase(
            getMileageDensity.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'kpis',
                            mutator: (data, current): void => {
                                current.mileageDensity = data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getMileageDensity.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['kpis']
                });
            }
        );

        builder.addCase(getFloorDistribution.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });
        builder.addCase(
            getFloorDistribution.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'kpis',
                            mutator: (data, current): void => {
                                current.floorDistribution = data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getFloorDistribution.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['kpis']
                });
            }
        );

        builder.addCase(getTripsHistory.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });
        builder.addCase(getTripsHistory.fulfilled, (state, {payload, meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'kpis',
                        mutator: (args, current): void => {
                            current.tripHistory = args;
                        }
                    }
                ]
            });
        });
        builder.addCase(getTripsHistory.rejected, (state, {meta, payload}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['kpis']
            });
        });

        builder.addCase(getOutOfServiceTotal.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });

        builder.addCase(
            getOutOfServiceTotal.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'kpis',
                            mutator: (arg, current): void => {
                                current.outOfServiceTotal = arg;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getOutOfServiceTotal.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['kpis']
                });
            }
        );

        builder.addCase(getDoorFaultsTotal.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });

        builder.addCase(
            getDoorFaultsTotal.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'kpis',
                            mutator: (arg, current): void => {
                                current.doorFaultsTotal = arg;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            getDoorFaultsTotal.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['kpis']
                });
            }
        );

        builder.addCase(getSafetyTestsTotal.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });

        builder.addCase(
            getSafetyTestsTotal.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'kpis',
                            mutator: (arg, current): void => {
                                current.safetyTestsTotal = arg;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getSafetyTestsTotal.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['kpis']
                });
            }
        );

        builder.addCase(getSanitizationRunsTotal.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });

        builder.addCase(
            getSanitizationRunsTotal.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'kpis',
                            mutator: (arg, current): void => {
                                current.sanitizationRunsTotal = arg;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            getSanitizationRunsTotal.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['kpis']
                });
            }
        );

        // StopsDensityKPI

        builder.addCase(getStopsDensity.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });

        builder.addCase(getStopsDensity.fulfilled, (state, {payload, meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'kpis',
                        mutator: (arg, current): void => {
                            current.stopsDensity = arg;
                        }
                    }
                ]
            });
        });

        builder.addCase(getStopsDensity.rejected, (state, {meta, payload}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['kpis']
            });
        });

        // EnergyConsumptionDensityKPI

        builder.addCase(
            getEnergyConsumptionDenstiy.pending,
            (state, {meta}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: ['kpis']
                });
            }
        );

        builder.addCase(
            getEnergyConsumptionDenstiy.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'kpis',
                            mutator: (arg, current): void => {
                                current.energyConsumptionDensity = arg;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            getEnergyConsumptionDenstiy.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['kpis']
                });
            }
        );

        // TimeConsumptionDensityKPI

        builder.addCase(getTimeConsumptionDensity.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });

        builder.addCase(
            getTimeConsumptionDensity.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'kpis',
                            mutator: (arg, current): void => {
                                current.timeConsumptionDensity = arg;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            getTimeConsumptionDensity.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['kpis']
                });
            }
        );
        // DON KPI
        builder.addCase(getDoorCyclesDensity.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });
        builder.addCase(
            getDoorCyclesDensity.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'kpis',
                            mutator: (data, current): void => {
                                current.doorCyclesDensity = data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getDoorCyclesDensity.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.elevatorSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['kpis']
                });
            }
        );

        //

        builder.addCase(getPermissions.pending, (state, {meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['permissions']
            });
        });
        builder.addCase(getPermissions.fulfilled, (state, {payload, meta}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'permissions',
                        replacer: (data): IResolvedPermissions => {
                            return data;
                        }
                    }
                ]
            });
        });
        builder.addCase(getPermissions.rejected, (state, {meta, payload}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['permissions']
            });
        });

        builder.addCase(updateElevatorTeam.pending, (state, {meta}) => {
            if (
                meta.arg.elevatorId !==
                    state.remotes.elevatorSummary.current.entityId ||
                !meta.arg.affinityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                meta,
                state,
                targets: [
                    {
                        key: 'teams',
                        mutator: (arg, current): void => {
                            current[arg.affinityId] = current[
                                arg.affinityId
                            ].map((member) => {
                                if (member.userId === arg.userId) {
                                    return {
                                        ...member,
                                        role: arg.role
                                    };
                                } else {
                                    return member;
                                }
                            });
                        }
                    }
                ]
            });
        });
        builder.addCase(
            updateElevatorTeam.fulfilled,
            (state, {meta, payload}) => {
                if (
                    meta.arg.elevatorId !==
                    state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['teams']
                });
            }
        );
        builder.addCase(
            updateElevatorTeam.rejected,
            (state, {meta, payload}) => {
                if (
                    meta.arg.elevatorId !==
                        state.remotes.elevatorSummary.current.entityId ||
                    !meta.arg.affinityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['teams']
                });
            }
        );

        builder.addCase(deleteElevatorTeam.pending, (state, {meta}) => {
            if (
                meta.arg.elevatorId !==
                    state.remotes.elevatorSummary.current.entityId ||
                !meta.arg.affinityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'teams',
                        mutator: (arg, current): void => {
                            current[arg.affinityId] = current[
                                arg.affinityId
                            ].filter((member) => {
                                return member.userId !== arg.userId;
                            });
                        }
                    }
                ]
            });
        });
        builder.addCase(
            deleteElevatorTeam.fulfilled,
            (state, {meta, payload}) => {
                if (
                    meta.arg.elevatorId !==
                    state.remotes.elevatorSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['teams']
                });
            }
        );
        builder.addCase(
            deleteElevatorTeam.rejected,
            (state, {meta, payload}) => {
                if (
                    meta.arg.elevatorId !==
                        state.remotes.elevatorSummary.current.entityId ||
                    !meta.arg.affinityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['teams']
                });
            }
        );

        builder.addCase(getServiceCompanyTeam.pending, (state, {meta}) => {
            const delegator = state.remotes.permissions.current.access.find(
                (access) => {
                    return access.entityId === meta.arg.serviceCompanyId;
                }
            );

            if (!delegator || !delegator.isDelegated) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['globalTeams']
            });
        });
        builder.addCase(
            getServiceCompanyTeam.fulfilled,
            (state, {meta, payload}) => {
                const delegator = state.remotes.permissions.current.access.find(
                    (access) => {
                        return access.entityId === meta.arg.serviceCompanyId;
                    }
                );

                if (!delegator || !delegator.isDelegated) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'globalTeams',
                            mutator: (data, current): void => {
                                current[meta.arg.serviceCompanyId] = data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getServiceCompanyTeam.rejected,
            (state, {meta, payload}) => {
                const delegator = state.remotes.permissions.current.access.find(
                    (access) => {
                        return access.entityId === meta.arg.serviceCompanyId;
                    }
                );

                if (!delegator || !delegator.isDelegated) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['globalTeams']
                });
            }
        );

        builder.addCase(getSiteTeam.pending, (state, {meta}) => {
            const delegator = state.remotes.permissions.current.access.find(
                (access) => {
                    return access.entityId === meta.arg.siteId;
                }
            );

            if (!delegator || !delegator.isDelegated) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['globalTeams']
            });
        });
        builder.addCase(getSiteTeam.fulfilled, (state, {meta, payload}) => {
            const delegator = state.remotes.permissions.current.access.find(
                (access) => {
                    return access.entityId === meta.arg.siteId;
                }
            );

            if (!delegator || !delegator.isDelegated) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'globalTeams',
                        mutator: (data, current): void => {
                            current[meta.arg.siteId] = data;
                        }
                    }
                ]
            });
        });
        builder.addCase(getSiteTeam.rejected, (state, {meta, payload}) => {
            const delegator = state.remotes.permissions.current.access.find(
                (access) => {
                    return access.entityId === meta.arg.siteId;
                }
            );

            if (!delegator || !delegator.isDelegated) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['globalTeams']
            });
        });

        builder.addCase(addMember.pending, (state, {meta}) => {
            const delegator = state.remotes.permissions.current.access.find(
                (access) => {
                    return access.entityId === meta.arg.affinityId;
                }
            );

            if (!delegator || !delegator.isDelegated) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'teams',
                        mutator: (arg, current): void => {
                            current[arg.affinityId] = [
                                ...current[arg.affinityId],
                                ...arg.assignments.map((user) => ({
                                    userId: user.userId,
                                    status: EAffiliationStatus.Active,
                                    role: user.role as EElevatorRole,
                                    affinityId: arg.affinityId,
                                    affinityType: delegator.entityType,
                                    attributes: []
                                }))
                            ];
                        }
                    }
                ]
            });
        });
        builder.addCase(addMember.fulfilled, (state, {meta, payload}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['teams']
            });
        });
        builder.addCase(addMember.rejected, (state, {meta, payload}) => {
            if (
                state.remotes.elevatorSummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.elevatorSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['teams']
            });
        });

        // STOPS

        builder.addCase(listElevatorStops.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['stops']
            });
        });

        builder.addCase(
            listElevatorStops.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'stops',
                            replacer: (data): IElevatorStop[] => {
                                return data.sort((a, b) => {
                                    return a.key < b.key ? -1 : 1;
                                });
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            listElevatorStops.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['stops']
                });
            }
        );

        // UPDATE STOPS
        builder.addCase(updateElevatorStops.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'stops',
                        replacer: (data, current): IElevatorStop[] => {
                            const selectedStop = data.content[0];

                            const index = current.findIndex(
                                (stop) =>
                                    stop.elevatorId ===
                                        selectedStop.elevatorId &&
                                    stop.stopId === selectedStop.stopId
                            );

                            const stops = [...current];
                            stops[index] = selectedStop;

                            return stops;
                        }
                    }
                ]
            });
        });

        builder.addCase(
            updateElevatorStops.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['stops']
                });
            }
        );

        builder.addCase(
            updateElevatorStops.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['stops']
                });
            }
        );

        // DESTINATION

        builder.addCase(listElevatorDestinations.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['destinations']
            });
        });
        builder.addCase(
            listElevatorDestinations.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'destinations',
                            replacer: (data): IElevatorDestination[] => {
                                return data.sort((a, b) => {
                                    return a.name.localeCompare(b.name);
                                });
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            listElevatorDestinations.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['destinations']
                });
            }
        );

        // UPDATE DESTINATIONS

        builder.addCase(updateElevatorDestinations.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'destinations',
                        replacer: (data, current): IElevatorDestination[] => {
                            const selectedDestination = data.content[0];

                            const index = current.findIndex(
                                (destination) =>
                                    destination.elevatorId ===
                                        selectedDestination.elevatorId &&
                                    destination.destinationId ===
                                        selectedDestination.destinationId
                            );

                            const destinations = [...current];

                            destinations[index] = selectedDestination;

                            return destinations;
                        }
                    }
                ]
            });
        });

        builder.addCase(
            updateElevatorDestinations.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['destinations']
                });
            }
        );

        builder.addCase(
            updateElevatorDestinations.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['destinations']
                });
            }
        );

        //

        builder.addCase(getElevatorIssues.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['issues']
            });
        });
        builder.addCase(
            getElevatorIssues.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'issues',
                            replacer: (data): IElevatorIssue[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getElevatorIssues.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['issues']
                });
            }
        );

        builder.addCase(getElevatorTrips.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['trips']
            });
        });
        builder.addCase(
            getElevatorTrips.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'trips',
                            replacer: (data): IElevatorTrip[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(getElevatorTrips.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['trips']
            });
        });

        builder.addCase(getElevatorAnomalies.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['anomalies']
            });
        });
        builder.addCase(
            getElevatorAnomalies.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'anomalies',
                            replacer: (data): IElevatorAnomaly[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getElevatorAnomalies.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['anomalies']
                });
            }
        );

        // EMERGENCIES

        builder.addCase(getElevatorEmergency.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['emergencies']
            });
        });
        builder.addCase(
            getElevatorEmergency.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'emergencies',
                            replacer: (data): IEmergency => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getElevatorEmergency.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['emergencies']
                });
            }
        );

        // RESTORE EMERGENCY

        builder.addCase(restoreElevatorEmergency.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['emergencies', 'elevatorSummary']
            });
        });
        builder.addCase(
            restoreElevatorEmergency.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'emergencies',
                            replacer: (data): IEmergency => {
                                return data;
                            }
                        },
                        {
                            key: 'elevatorSummary',
                            mutator: (_, current): void => {
                                current.status = EElevatorStatus.Normal;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            restoreElevatorEmergency.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['emergencies', 'elevatorSummary']
                });
            }
        );

        // ENABLE REMOTE CONTROL

        builder.addCase(updateRemoteControlMode.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['elevatorGeneralSettings']
            });
        });
        builder.addCase(
            updateRemoteControlMode.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'elevatorGeneralSettings',
                            mutator: (_, current): void => {
                                current.remoteControl.desired = meta.arg.enable;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            updateRemoteControlMode.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorGeneralSettings']
                });
            }
        );
    }
});

export {elevatorSlice};
